.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.grid-container {
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: "Filter Logo User";
  flex: 0 0 auto; 
}

.Filter {
  grid-area: Filter;
  justify-self: center;
  align-self: center;
}

.Logo {
  grid-area: Logo;
  justify-self: center;
}

.User {
  grid-area: User;
  justify-self: right;
  align-self: center;
}

.User>span {
  font-size: 16px;
  font-weight: 900;
  margin: 0px 10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.report-style-class {
  height: 100%;
}

.App-header {
  background-color: #282c34;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@media screen and (max-width: 640px) {
  .grid-container {
    space-around: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: "Logo User";
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.welcome-logo img {
  width: 50px;
  padding: 5px;
}

.header {
  background-color: #dadae3;

}

#reports-list p {
  margin-bottom: 11px !important;
  color: #6264a7;
  font-family: inherit;
  font-size: medium;
  font-weight: 400;
  position: relative;
  display: block;
  padding: 7px 20px;
  text-decoration: none;
}

#reports-list p:hover {
  background-color: #f8f9fa;
  cursor: pointer;
}

.refresh-btn .ui-box {
  height: 2rem !important;
  width: 2rem !important;
}

.refresh-btn svg {
  width: 1.5rem;
  height: 1.5rem;
}

.ui-menu {
  justify-content: center !important;
}

.report-mean-div {
  height: 92vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.report-header{
  font-size: 16px;
   margin: 10px;
}
.hisorique-header-div{
  display: flex;
   justify-content: center
}
.loader-header-historique{
  margin-left: 20px;
  margin-right: 20px;
}
.PowerBIEmbed-parent-div{
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
}
.PowerBIEmbed-child-div{
  transform: scale(0.8);
  transform-origin: top left;
  width: 125%;
  height: 125%;
  position: absolute;
  top: 0;
  left: 0
}
.PowerBIEmbed{
  height: 100%;
  width: 100%;
}